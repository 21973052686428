import { ReactPortal, useLayoutEffect, useRef, useState } from 'react';

import styled from '@emotion/styled';
import { useEventListener } from '@innovamat/hooks';
import { createPortal } from 'react-dom';

type Props = {
  element: React.ReactNode;
  isOpen: boolean;
  onClose?: (e?: any) => void;
  closeOnClickOutside?: boolean;
  dataTestId?: string;
  closeOnEsc?: boolean;
  zIndex?: number;
};

const Overlay = styled.div<{ zIndex?: number; isOpen?: boolean }>`
  position: fixed;
  z-index: ${({ zIndex }) => (zIndex ? zIndex : '30')};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) =>
    theme.tokens.color.specific.element.overlay.value};
  overflow-y: auto;
  animation: ${({ isOpen }) => (isOpen ? 'fadeIn 0.2s ease-in-out' : 'none')};

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const ElementWrapper = styled.div<{
  heightOffset: number;
  zIndex?: number;
  isOpen: boolean;
}>`
  position: absolute;
  z-index: ${({ zIndex }) => (zIndex ? zIndex : '31')};
  top: 50%;
  left: 50%;
  padding: ${({ heightOffset }) => (heightOffset ? `8px 0` : `0`)};

  transform: ${({ heightOffset }) =>
    heightOffset
      ? `translate(-50%, calc(-50% + ${heightOffset / 2}px))`
      : `translate(-50%, -50%)`};
  img {
    max-height: 90vh;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: ${({ heightOffset }) => (heightOffset ? `8px 0 32px 0` : `0`)};
  }

  animation: ${({ isOpen }) => (isOpen ? 'SlideIn 0.3s ease-out' : 'none')};

  @keyframes SlideIn {
    from {
      top: 51%;
    }
    to {
      top: 50%;
    }
  }
`;

export function ModalOverlay({
  element,
  isOpen,
  onClose,
  closeOnClickOutside,
  dataTestId,
  closeOnEsc,
  zIndex,
}: Props): ReactPortal | null {
  const getModalWrapper = () => {
    if (typeof document === 'undefined') return null;
    return document.getElementById('modal') || document.body;
  };

  const modalWrapper = getModalWrapper();

  const ref = useRef<HTMLDivElement>(null);
  const [heightOffset, setHeightOffset] = useState(0);

  useLayoutEffect(() => {
    if (!ref.current) return;

    const height = ref.current.clientHeight;

    setHeightOffset(
      height > window.innerHeight ? height - window.innerHeight : 0
    );

    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [ref.current?.clientHeight, isOpen]);

  useEventListener('keydown', (event) => {
    if (closeOnEsc && event.key === 'Escape') {
      onClose?.();
    }
  });

  if (!isOpen || !modalWrapper) return null;
  return createPortal(
    <Overlay
      isOpen={isOpen}
      onClick={closeOnClickOutside ? onClose : () => {}}
      data-testid={dataTestId}
      zIndex={zIndex ? zIndex - 1 : undefined}
    >
      <ElementWrapper
        ref={ref}
        heightOffset={heightOffset}
        zIndex={zIndex}
        isOpen={isOpen}
      >
        <div onClick={(e) => e.stopPropagation()}>{element}</div>
      </ElementWrapper>
    </Overlay>,
    modalWrapper
  );
}
